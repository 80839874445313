import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// Pages
import {
  Home,
  AboutUs,
  ArtificialIntelligence,
  MobileAppDevelopment,
  WebsiteDevelopment,
  DigitalMarketing,
  Seo,
  SocialMediaMarketing,
  WebHosting,
  SuccessStory,
  ContactUs,
  NotFound,
} from "./../../pages";
const RouterNavigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route
          path="/services/artificial-intelligence"
          element={<ArtificialIntelligence />}
        />
        <Route
          path="/services/mobile-app-development"
          element={<MobileAppDevelopment />}
        />
        <Route
          path="/services/website-development"
          element={<WebsiteDevelopment />}
        />
        <Route
          path="/services/digital-marketing"
          element={<DigitalMarketing />}
        />
        <Route path="/services/seo" element={<Seo />} />
        <Route
          path="/services/social-media-marketing"
          element={<SocialMediaMarketing />}
        />
        <Route path="/services/web-hosting" element={<WebHosting />} />
        <Route path="/success-story" element={<SuccessStory />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
export default RouterNavigation;
