import React, { useEffect } from "react";
// layout
import Layout from "../../../layout";
// components
import {
  Breadcrumbs,
  StepCard,
  GetInTouch,
  ContactUsSection,
} from "./../../../components";
// material ui
import { Box, Grid } from "@mui/material";
// images
import {
  ArtificialIntelligenceImage,
  ArtificialIntelligenceImage2,
  ArtificialIntelligenceHeader
} from "./../../../assets/images";
// styles
import "./../../../styles/artificial-intelligence.css";
// icons
import {
  ArtificialIntelligenceIcon1,
  ArtificialIntelligenceIcon2,
  ArtificialIntelligenceIcon3,
  ArtificialIntelligenceIcon4,
} from "./../../../assets/icons";
// animation
import { Fade } from "react-reveal";
const ArtificialIntelligence = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const CardList = [
    {
      icon: ArtificialIntelligenceIcon1,
      title: "Data Analyst",
      description:
        "Data analysis is a process of inspecting, cleansing, transforming, and modelling data with the goal of discovering useful information, informing conclusions. We specialize in Sentiment analyst, predictive analysis, financial analysis, positive and negative analysis.",
    },
    {
      icon: ArtificialIntelligenceIcon2,
      title: "Bots Development",
      description:
        "Smart bot’s development services using Artificial Intelligence. GMITSTECH is the leading AI chatbot and conversational platform developer for Facebook Messenger, WhatsApp, Telegram, Viber and other platforms. Multilingual intelligent bots that converse with your customers",
    },
    {
      icon: ArtificialIntelligenceIcon3,
      title: "Business Forecasting",
      description:
        "Predict developments in businesses, such as sales, expenditures, and profits. The purpose of business forecasting is to develop better strategies based on these informed predictions and achieve goals",
    },
    {
      icon: ArtificialIntelligenceIcon4,
      title: "Data Scraping",
      description:
        "Data scraping services. collect structured data in an automated fashion from various sources. We Extract the Data you Need & Deliver it Exactly as You'd Like",
    },
  ];
  return (
    <Layout>
      <Breadcrumbs heading="Artificial Intelligence" show={true} image={ArtificialIntelligenceHeader} />
      <div className="pages-section">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
            <div className="pages-section-data-main">
              <Fade bottom delay={400}>
                <h1 className="pages-section-heading">
                  AI Services and Solutions
                </h1>
              </Fade>
              <Fade bottom delay={800}>
                <p className="pages-section-description">
                  Discover and transform your business using the right
                  automation and AI solutions Technologies like machine learning
                  and natural language processing are all part of the AI
                  landscape. Each one is evolving along its own path and, when
                  applied in combination with data, analytics and automation,
                  can help businesses achieve their goals, be it improving
                  customer service or optimizing the supply chain organizations
                  are starting to see how AI can add and multiply value for
                  them. Automation cuts costs and brings new levels of
                  consistency, speed and scalability to business processes.
                </p>
              </Fade>
              </div>
            </Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <Fade bottom delay={1200}>
              <div className="pages-section-image-main">
                <img
                  src={ArtificialIntelligenceImage}
                  alt="Artificial Intelligence"
                  className="pages-section-image"
                />
                </div>
              </Fade>
            </Grid>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Box>
      </div>
      <StepCard
        heading="OUR AI EXPERT SERVICES"
        subHeading=""
        subHeading2=""
        subHeading3=""
        CardList={CardList}
      />
      <div className="pages-section">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
            <div className="pages-section-data-main">
              <Fade bottom delay={400}>
                <h1 className="pages-section-heading">
                  Why should you opt for AI Solutions?
                </h1>
              </Fade>
              <Fade bottom delay={800}>
                <p className="pages-section-description">
                  We develop easy to use AI solutions for your complex business
                  risks and strive to improve your business efficiency in
                  real-time.
                </p>
              </Fade>
              <Fade bottom delay={1200}>
                <ul className="artificial-intelligence-list">
                  <li>End-to-end efficiency</li>
                  <li>Improved accuracy and decision-making</li>
                  <li>Intelligent offerings</li>
                  <li>Superior customer service</li>
                </ul>
              </Fade>
              </div>
            </Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <Fade bottom delay={1600}>
              <div className="pages-section-image-main">
                <img
                  src={ArtificialIntelligenceImage2}
                  alt="Artificial Intelligence"
                  className="pages-section-image"
                />
                </div>
              </Fade>
            </Grid>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Box>
      </div>
      <GetInTouch />
      <ContactUsSection />
    </Layout>
  );
};
export default ArtificialIntelligence;
