import React, { useEffect } from "react";
// layout
import Layout from "../../layout";
// components
import {  ContactUsSection,Breadcrumbs } from "./../../components";
// image 
import {WebHostingHeader} from './../../assets/images'
const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
 {/* <img src={ContactImage} alt="contact" style={{width:"100%"}}/> */}
 <Breadcrumbs heading="Contact Us" image={WebHostingHeader}/>
      <ContactUsSection />
    </Layout>
  );
};
export default ContactUs;
