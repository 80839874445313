import React, { useEffect } from "react";
// styles
import "./../../styles/home.css";
// layout
import Layout from "../../layout";
// componenets
import {
  Tabs,
  Testimonials,
  Portfolio,
  Clients,
  TestimonialsVideo,
  GetInTouch,
  ContactUsSection,
} from "./../../components";
// material ui
import { Box, Grid, Button } from "@mui/material";
// images
import {
  HomeSection1User,
  HomeSection1Image,
  HomeSection2Image,
  HomeTab1Image,
  HomeTab2Image,
  HomeTab3Image,
  MobileMockupSlider1,
  MobileMockupSlider2,
  MobileMockupSlider3,
  MobileMockupSlider4,
  MobileMockupSlider5,
  MobileMockupSlider6,
  MobileMockupSlider7,
  MobileMockupSlider8,
  MobileMockupSlider9,
} from "./../../assets/images";
// animation
import { Fade } from "react-reveal";
// router
import { useNavigate } from "react-router-dom";
// icons
import {
  MobileAppDevelopmentWhiteIcon,
  WebsiteDevelopmentWhiteIcon,
  WebHostingWhiteIcon,
  ArtificialIntelligenceWhiteIcon,
  SocialMediaWhiteIcon,
  DigitalMarketingWhiteIcon,
  InnovationIcon,
  InnovationWhiteIcon,
  ResultIcon,
  ResultWhiteIcon,
  IdeasIcon,
  IdeasWhiteIcon,
} from "./../../assets/icons";
// slider
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import MockupImage from "./../../assets/images/mobile-mockup.png";
const Home = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1800 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1800, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      removeArrowOnDeviceType: "tablet",
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const CustomDot = ({ onMove, index, onClick, active }) => {
    return (
      <li
        className={active ? "active" : "inactive"}
        onClick={() => onClick()}
      ></li>
    );
  };
  const List = [
    {
      image: MobileMockupSlider1,
    },

    {
      image: MobileMockupSlider2,
    },
    {
      image: MobileMockupSlider3,
    },
    {
      image: MobileMockupSlider4,
    },
    {
      image: MobileMockupSlider5,
    },
    {
      image: MobileMockupSlider6,
    },
    {
      image: MobileMockupSlider7,
    },
    {
      image: MobileMockupSlider8,
    },
    {
      image: MobileMockupSlider9,
    },
  ];
  const TabsList = [
    {
      icon: InnovationIcon,
      activeIcon: InnovationWhiteIcon,
      title: "INNOVATION IS",
      title2: "CRUCIAL",
      heading:
        "INNOVATION IS CRUCIAL TO THE CONTINUING SUCCESS OF ANY ORGANIZATION",
      description:
        "We create an environment that encourages innovative thinking. Of course, an idea must be replicable at an economical cost and should cater to the specific needs and expectation of our customers. Our goal is to identify your pain point and meticulously diagnose and investigate on the right strategy to apply that carves the path to digitalization and enhances performance. We take pride in our work, and we have a solid track record of helping businesses thrive in the internet-connected world.",
      image: HomeTab1Image,
    },
    {
      icon: ResultIcon,
      activeIcon: ResultWhiteIcon,
      title: "RESULT-DRIVEN",
      title2: "APPROACHES",
      heading: "WE OFFER RESULT-DRIVEN APPROACHES",
      description:
        "We are one-stop-shop that caters to all your business requirements, be it business solutions or marketing and promoting your product. Our highly experienced and service-oriented team is always available for guidance and free consultation. We offer a full range of integrated communication services ranging from branding, strategic planning, advertising, graphic designing, integrated campaign, digital marketing, social media planning, mobile app development, website development, SEO and much more.",
      image: HomeTab2Image,
    },
    {
      icon: IdeasIcon,
      activeIcon: IdeasWhiteIcon,
      title: "BRING YOUR IDEAS",
      title2: "TO LIFE",
      heading: "HOW DO WE BRING YOUR IDEAS TO LIFE?",
      description:
        "Our targeted high-performance campaigns have helped our clients in various industries to compete and rise above the challenges they face daily. We work closely with our clients to understand their business module and desired goals. Then we survey current market trends that are suitable for their specific industry.",
      image: HomeTab3Image,
    },
  ];
  return (
    <Layout>
      <div className="home-section-1">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <Fade bottom>
                <div className="home-section-1-c-1-header">
                  <h1 className="home-section-1-heading">
                    WELCOME TO GMITS TECH
                  </h1>
                  <p className="home-section-1-line"></p>
                </div>
                <p className="home-section-1-heading-2">
                  Leading IT Company in{" "}
                  <span className="home-section-1-country-name">UAE</span>,{" "}
                  <span className="home-section-1-country-name">INDONESIA</span>
                  , <span className="home-section-1-country-name">IRAQ</span>,{" "}
                  <span className="home-section-1-country-name">INDIA</span> &{" "}
                  <span className="home-section-1-country-name">PAKISTAN</span>
                </p>
                <Button
                  type="text"
                  className="home-section-1-button"
                  onClick={() => navigate("/contact-us")}
                >
                  get quote
                </Button>
              </Fade>
            </Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <div className="home-section-1-c-2-container">
                <Fade left delay={400}>
                  <img
                    src={HomeSection1User}
                    className="home-section-1-user-image"
                    alt="user image"
                  />
                </Fade>
                <Fade down delay={400}>
                  <img
                    src={HomeSection1Image}
                    className="home-section-1-image"
                  />
                </Fade>
              </div>
            </Grid>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Box>
      </div>
      <div className="home-section-2">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <Fade top>
                <div className="home-section-2-data">
                  <h1 className="home-section-2-heading">ABOUT US</h1>
                  <p className="home-section-2-description">
                    GMITS is a premium IT solutions provider based in UAE,
                    INDONESIA, IRAQ, INDIA & PAKISTAN. Founded in 2016 by
                    experienced IT professionals, our vision is to get more eyes
                    on your business on various platforms, to help you achieve
                    growth and desired results. We listen, get to know you, and
                    collaborate with you to provide tailor-made solutions.
                  </p>
                  <div className="home-section-2-button-main">
                    <Button
                      className="home-section-2-button"
                      onClick={() => navigate("/about-us")}
                    >
                      learn more
                    </Button>
                  </div>
                </div>
              </Fade>
            </Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <div className="home-section-2-c-2-container">
                <img
                  src={HomeSection2Image}
                  className="home-section-3-image"
                  alt="user image"
                />
              </div>
            </Grid>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Box>
      </div>
      <div className="home-section-3">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0}>
            <Grid item xl={10} lg={11} md={11} sm={11} xs={12}>
              <Fade down delay={400}>
                <div className="home-section-3-inner">
                  <Box sx={{ flexGrow: 0 }}>
                    <Grid container spacing={0}>
                      <Grid
                        item
                        xl={4.5}
                        lg={3}
                        md={1.5}
                        sm={12}
                        xs={12}
                      ></Grid>
                      <Grid item xl={2.5} lg={3} md={3.5} sm={4} xs={12}>
                        <div className="home-section-3-data">
                          <p className="home-section-3-title">
                            Years of Services
                          </p>
                          <h1 className="home-section-3-counter">5+</h1>
                        </div>
                        <div className="home-section-3-data">
                          <p className="home-section-3-title">
                            Countries Offices
                          </p>
                          <h1 className="home-section-3-counter">5+</h1>
                        </div>
                      </Grid>
                      <Grid item xl={2.5} lg={3} md={3.5} sm={4} xs={12}>
                        <div className="home-section-3-data-2">
                          <p className="home-section-3-title">
                            Countries client base
                          </p>
                          <h1 className="home-section-3-counter">20+</h1>
                        </div>
                        <div className="home-section-3-data-2">
                          <p className="home-section-3-title">
                            Trusted Clients
                          </p>
                          <h1 className="home-section-3-counter">100+</h1>
                        </div>
                      </Grid>
                      <Grid item xl={2.5} lg={3} md={3.5} sm={4} xs={12}>
                        <div className="home-section-3-data">
                          <p className="home-section-3-title">Employees</p>
                          <h1 className="home-section-3-counter">45+</h1>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </Fade>
            </Grid>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Box>
      </div>
      <div className="home-section-4">
        <Fade bottom>
          <h1 className="home-section-4-heading">
            We Provide High <span>Quality Services</span>
            <br />
            That Meets Your Business Needs.
          </h1>
        </Fade>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={8} lg={10} md={10} sm={10} xs={12}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                  <Grid item xl={4} lg={4} md={3.5} sm={12} xs={12}>
                    <Fade bottom>
                      <Button
                        className="home-section-4-card"
                        variant="text"
                        disableRipple={true}
                        onClick={() =>
                          navigate("/services/mobile-app-development")
                        }
                      >
                        <div className="home-section-4-icon-main">
                          <img
                            src={MobileAppDevelopmentWhiteIcon}
                            alt="icon"
                            className="home-section-4-icon"
                          />
                        </div>
                        <h1 className="home-section-4-heading-2">
                          Mobile App Development
                        </h1>
                        <p className="home-section-4-description">
                          We Develop user-friendly cross platforms custom built
                          Mobile Apps using latest technology like React Js,
                          Angular, Swift, Python Etc. We offer full cycle from
                          designing, developing to managing services
                        </p>
                      </Button>
                    </Fade>
                    <Fade bottom>
                      <Button
                        className="home-section-4-card"
                        variant="text"
                        disableRipple={true}
                        onClick={() =>
                          navigate("/services/website-development")
                        }
                      >
                        <div className="home-section-4-icon-main">
                          <img
                            src={WebsiteDevelopmentWhiteIcon}
                            alt="icon"
                            className="home-section-4-icon"
                          />
                        </div>
                        <h1 className="home-section-4-heading-2">
                          Website Development
                        </h1>
                        <p className="home-section-4-description">
                          We specialize in custom build B2B & B2C portal,
                          wordpress, Vuejs, nodejs, HTML We develop user
                          friendly sites with modern designs.
                        </p>
                      </Button>
                    </Fade>
                    <Fade bottom>
                      <Button
                        className="home-section-4-card"
                        variant="text"
                        disableRipple={true}
                        onClick={() => navigate("/services/web-hosting")}
                      >
                        <div className="home-section-4-icon-main">
                          <img
                            src={WebHostingWhiteIcon}
                            alt="icon"
                            className="home-section-4-icon"
                          />
                        </div>
                        <h1 className="home-section-4-heading-2">
                          Web Hosting
                        </h1>
                        <p className="home-section-4-description">
                          We provide best website hosting packages with SSL
                          certification and unlimited emails If you have a
                          website and need website audit, we provide that as
                          well
                        </p>
                      </Button>
                    </Fade>
                  </Grid>
                  <Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
                    <div className="mobile-phone-mockup-container">
                      <img
                        src={MockupImage}
                        className="mobile-phone-mockup-image"
                      />
                      <div className="mobile-phone-mockup-slider-container">
                        <Carousel
                          responsive={responsive}
                          draggable={true}
                          swipeable={true}
                          removeArrowOnDeviceType="mobile"
                          showDots={true}
                          ssr={true} // means to render carousel on server-side.
                          infinite={true}
                          arrows={false}
                          renderDotsOutside={false}
                          rewindWithAnimation={true}
                          autoPlay={
                            props.deviceType !== "mobile" ? true : false
                          }
                          containerClass="carousel-container"
                          deviceType={props.deviceType}
                          dotListClass="custom-dot-list-style"
                          className="_carousel"
                          customDot={<CustomDot />}
                        >
                          {List.map((v, i) => {
                            return (
                              <div
                                className="mobile-phone-mockup-slider"
                                key={i}
                              >
                                <img
                                  src={v.image}
                                  alt={v.title}
                                  className="mobile-phone-mockup-slider-image"
                                />
                              </div>
                            );
                          })}
                        </Carousel>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xl={4} lg={4} md={3.5} sm={12} xs={12}>
                    <Fade bottom>
                      <Button
                        className="home-section-4-card"
                        variant="text"
                        disableRipple={true}
                        onClick={() =>
                          navigate("/services/artificial-intelligence")
                        }
                      >
                        <div className="home-section-4-icon-main">
                          <img
                            src={ArtificialIntelligenceWhiteIcon}
                            alt="icon"
                            className="home-section-4-icon"
                          />
                        </div>
                        <h1 className="home-section-4-heading-2">
                          Artifical / Intelligence
                        </h1>
                        <p className="home-section-4-description">
                          Utilize latest Artificial Intelligence principles and
                          applications to tap into structured and unstructured
                          user interactions across geographies and provide
                          meaningful insights on users’ trends, behaviors,
                          opinions, sentiments, and other interaction
                          experiences
                        </p>
                      </Button>
                    </Fade>
                    <Fade bottom>
                      <Button
                        className="home-section-4-card"
                        variant="text"
                        disableRipple={true}
                        onClick={() =>
                          navigate("/services/social-media-marketing")
                        }
                      >
                        <div className="home-section-4-icon-main">
                          <img
                            src={SocialMediaWhiteIcon}
                            alt="icon"
                            className="home-section-4-icon"
                          />
                        </div>
                        <h1 className="home-section-4-heading-2">
                          SEO /Social Media Services
                        </h1>
                        <p className="home-section-4-description">
                          Rank your website on the first page of Google Search
                          Engine, drive organic traffic to your website and
                          helps your business by getting genuine quality leads
                          which generates desired revenue.
                        </p>
                      </Button>
                    </Fade>
                    <Fade bottom>
                      <Button
                        className="home-section-4-card"
                        variant="text"
                        disableRipple={true}
                        onClick={() => navigate("/services/digital-marketing")}
                      >
                        <div className="home-section-4-icon-main">
                          <img
                            src={DigitalMarketingWhiteIcon}
                            alt="icon"
                            className="home-section-4-icon"
                          />
                        </div>
                        <h1 className="home-section-4-heading-2">
                          Digital Marketing
                        </h1>
                        <p className="home-section-4-description">
                          We work on various Digital Platforms like Social Media
                          Marketing, Google AdWords, You tube Marketing, SEO,
                          Lead Generation services, We provide complete Digital
                          Solutions.
                        </p>
                      </Button>
                    </Fade>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className="home-section-5">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={10} lg={11} md={11} sm={11} xs={12}>
              <Fade down delay={400}>
                <div className="home-section-5-inner">
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0}>
                      <Grid item xl={9.6} lg={9.6} md={11} sm={11} xs={12}>
                        <h1 className="home-section-5-heading">
                          customer satisfaction is our top priority
                        </h1>
                        <p className="home-section-5-description">
                          Our goal is to identify your pain point and
                          meticulously investigate on the right strategy to
                          apply that carves the path to digitalization and
                          enhances performance. We take pride in our work, and
                          we have a solid track record of helping businesses
                          thrive in the internet-connected world.
                        </p>
                      </Grid>
                      <Grid item xl={2.4} lg={2.4} md={1} sm={1} xs={12}></Grid>
                    </Grid>
                  </Box>
                </div>
              </Fade>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className="home-section-6">
        <Tabs
          heading="HOW WE "
          heading2="WORK"
          subHeading="Here Are Some Details"
          TabsList={TabsList}
        />
      </div>
      <Testimonials />
      <Portfolio />
      <Clients />
      <TestimonialsVideo />
      <GetInTouch />
      <ContactUsSection />
    </Layout>
  );
};
export default Home;
