import React, { useEffect } from "react";
// layout
import Layout from "../../../layout";
// components
import {
  Breadcrumbs,
  StepCard,
  Tabs,
  GetInTouch,
  ContactUsSection,
} from "./../../../components";
// material ui
import { Box, Grid } from "@mui/material";
// images
import {
  WebDevelopmentImage,
  WebDevelopmentTab1Image,
  WebDevelopmentTab2Image,
  WebDevelopmentTab3Image,
  WebDevelopmentTab4Image,
  MobileAppDevelopmentHeader
} from "./../../../assets/images";
// animation
import { Fade } from "react-reveal";
// icons
import {
  WebDesignWhiteIcon,
  WebsiteDevelopmentWhiteIcon,
  WebOptimisationIcon,
  WebsitePromotionIcon,
  WebsiteDevelopmentActiveIcon,
  EcommerceIcon,
  EcommerceWhiteIcon,
  CmsIcon,
  CmsWhiteIcon,
  FactsIcon,
  FactsWhiteIcon,
} from "./../../../assets/icons";
// styles
import "./../../../styles/web-development.css";
const WebsiteDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const CardList = [
    {
      icon: WebDesignWhiteIcon,
      title: "Website Design",
      description:
        "The process of website development starts by analysing and understanding your requirements, goals and the vision you have in mind After going through your complete project goal, we provide suitable design & content that fits your needs. A website is accountable and creates a profound impact on your business.",
    },
    {
      icon: WebsiteDevelopmentWhiteIcon,
      title: "Website Development",
      description:
        "After designing the critical phase, we select the latest technology for development. Our highly qualified developers work on latest technologies like Php, angular js, react, Asp.net etc. The use of technology plays a vital role in the speed and security of the website.",
    },
    {
      icon: WebOptimisationIcon,
      title: "Website Optimisation",
      description:
        "As soon as the site is ready, we start the process of optimization of the content The site is uploaded correctly and optimized for Google search queries. The optimization is vital for SEO. Our experienced content writers ensures that the content they write is purely original and free from plagiarism",
    },
    {
      icon: WebsitePromotionIcon,
      title: "Website Promotion",
      description:
        "The final stage of development leads to promotion phase. In this phase, the full-fledged site that is built on a staging environment that moves to a production environment and made visible for public view. We provide best offers and packages for hosting and domain to help our customers with hassle-free site development.",
    },
  ];
  const TabsList = [
    {
      icon: WebsiteDevelopmentActiveIcon,
      activeIcon: WebsiteDevelopmentWhiteIcon,
      title: "WEB DESIGN &",
      title2: "DEVELOPMENT",
      heading: "CUSTOM WEB DESIGN & DEVELOPMENT",
      description:
        "GMITS Tech is the best Web development company in dubai. Our team specializes in innovative custom made web development solutions.With a mind-set of adding value to your business We create dynamic, flexible and responsive web solution with a high-level online presence, interactive design and content that will captivate visitors and surely increase customer satisfaction.",
      image: WebDevelopmentTab1Image,
    },
    {
      icon: EcommerceIcon,
      activeIcon: EcommerceWhiteIcon,
      title: "ECOMMERCE & CUSTOM",
      title2: "WEB APPS",
      heading: "ECOMMERCE & CUSTOM WEB APPS",
      description:
        "Build your business by selling your products online We provide cost effective custom built market place development with outstanding features and functionality that gain customers loyalty. We focus on user friendly interface so the customer can order your products easily. Some of the features include Social Login | Multi-Language | Multi-Currency | Unlimited products and Categories | Easy Checkout | Reviews & Ratings | Mobile-Friendly | Beautiful & Customizable Interface | User-Friendly Features and Functionalities | Exclusive Profile Pages for Sellers |Custom URLs for Stores and much more",
      image: WebDevelopmentTab2Image,
    },
    {
      icon: CmsIcon,
      activeIcon: CmsWhiteIcon,
      title: "FAST DEVELOPMENT",
      title2: "USING CMS",
      heading: "FAST DEVELOPMENT USING CMS",
      description:
        "Content Management System is a quick and easy method to get your website done in just a few days. Are you looking for an interactive website and you do not have much time? Our highly skilled developers can create your website in no time.  WordPress, Jumla, Drupal Laravel etc. These technologies can be used to develop the best possible designed website. With the 100% customer satisfaction and dedicated team, GMITS holds a valuable and reliable position in the market when it comes to CMS.",
      image: WebDevelopmentTab3Image,
    },
    {
      icon: FactsIcon,
      activeIcon: FactsWhiteIcon,
      title: "FACTS BEHIND",
      title2: "DEVELOPMENT",
      heading: "WE USE LATEST TOOLS & TECHNOLOGY",
      description:
        "In This Ever Evolving Era Of Technologies, Online Presence Is Imperative For All The Organizations. And The First Step To Online Presence Is Having A Website. A Website Itself Is Utilize To Score Many Different Marketing Strategies To Commemorate The Business Growth.The Best Thing About Website Is That Your Customer Can Get To Know Your Product At Their Convenience. Online Presence Helps To Provoke Engagement That Inspires And Creates Economic Gain For Your Business",
      image: WebDevelopmentTab4Image,
    },
  ];
  return (
    <Layout>
       <Breadcrumbs heading="Website Development" show={true} image={MobileAppDevelopmentHeader}/>
      <div className="pages-section">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
            <div className="pages-section-data-main">
              <Fade bottom delay={400}>
                <h1 className="pages-section-heading">
                  BEST & AFFORDABLE WEB DEVELOPMENT COMPANY
                </h1>
              </Fade>
              <Fade bottom delay={800}>
                <p className="pages-section-description">
                  We focus on latest and trendy UI/UX user friendly interface
                  that promotes product image, which leads to customer
                  engagement and help in generating high conversion ratio.
                  <br />
                  GMITS Tech is the best Web development company in Dubai. We
                  have best web developer in Dubai and Our team specializes in
                  innovative custom made web development.
                  <br />
                  We define and analyse your products, services and business
                  goals and based on the research we design and develop
                  subsequently to add value to your business and online
                  presence.
                </p>
              </Fade>
              </div>
            </Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <Fade bottom delay={1200}>
              <div className="pages-section-image-main">
                <img
                  src={WebDevelopmentImage}
                  alt="Website Development"
                  className="pages-section-image"
                />
                </div>
              </Fade>
            </Grid>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Box>
      </div>
      <StepCard
        heading="WEB DEVELOPMENT SERVICES THAT DRIVE SUPERIOR OUTCOMES"
        subHeading=""
        subHeading2=""
        subHeading3=""
        description="We are a top-notch web design and development company that can help you define your brand and increase your products/ or services demand through a customer-centric and data-driven approach. We understand the importance of having an engaging website in today’s digital era and make sure to incorporate all digital marketing aspects like SEO, PPC, content marketing, and more to yield outstanding results."
        CardList={CardList}
      />
      <Tabs
        heading="OUR "
        heading2="EXPERTISE"
        subHeading="Our highly experienced team of developers work on latest ongoing trends and technology with SEO perspective in mind to achieve the best outcome for your company."
        TabsList={TabsList}
      />
      <div className="web-development-section">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={8} lg={10} md={10} sm={10} xs={12}>
              <h1 className="web-development-section-heading">
                HOW TO START WITH GMITS
              </h1>
              <p className="web-development-section-description">
                The Development Of A Website Is Smooth Sailing And Economical.
                <br />
                GMITS Provides Well Qualified And Experienced Technical
                Individuals Who Serve Our Clients And Work With Dedication On
                Each And Every Project.
                <br />
                All You Need To Do Is Share Your Ideas And Goals You Have In
                Mind To Our Requirement Gathering Analyst.
                <br />
                We Also Provide Samples Of Latest And Best Designs That Will Go
                Well With Your Business.
                <br />
                We Have Vast Experience In Website Development As It Plays
                Crucial Role In The Marketing Of Business Of Any Scale. You
                Still Don’t Have A Website? Do You Need One? Allow us to develop
                for you.
                <br />
                You Have A Website But The Design Is Not Attractive? You Need To
                Redesign? Let us redesign for you.
              </p>
            </Grid>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Box>
      </div>
      <GetInTouch />
      <ContactUsSection />
    </Layout>
  );
};
export default WebsiteDevelopment;
