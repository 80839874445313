import React from "react";
// styles
import "./../../styles/step-card.css";
// material ui
import { Box, Grid } from "@mui/material";
// animation
import { Fade } from "react-reveal";
const StepCard = (props) => {
  return (
    <div className="step-card-container">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xl={3} lg={2} md={1} sm={1} xs={12}></Grid>
          <Grid item xl={6} lg={8} md={10} sm={10} xs={12}>
          <div className="step-card-header">
        <Fade bottom delay={400}>
          <h1 className="step-card-heading">{props.heading}</h1>
        </Fade>
        {props.subHeading !== "" && (
          <Fade bottom delay={800}>
            <h1 className="step-card-sub-heading">
              {props.subHeading}
              <span>
                {props.subHeading2}
                <br />
              </span>
              {props.subHeading3}
            </h1>
          </Fade>
        )}
        <Fade bottom delay={800}>
          <p className="step-card-description">{props.description}</p>
        </Fade>
      </div>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={3}>
                {props.CardList.map((v, i) => {
                  return (
                    <Grid
                      item
                      xl={props.card === 3 ? 4 : 6}
                      lg={props.card === 3 ? 4 : 6}
                      md={props.card === 3 ? 4 : 6}
                      sm={12}
                      xs={12}
                      key={i}
                    >
                      <Fade bottom delay={i * 400}>
                        <div className="step-card-1">
                          <div className="step-card-1-icon-main">
                            <img
                              src={v.icon}
                              alt="icon"
                              className="step-card-1-icon"
                            />
                          </div>
                          <h1 className="step-card-1-title">{v.title}</h1>
                          <p className="step-card-1-description">
                            {v.description}
                          </p>
                        </div>
                      </Fade>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
          <Grid item xl={3} lg={2} md={1} sm={1} xs={12}></Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default StepCard;
