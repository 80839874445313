import React, { useState } from "react";
// material ui
import {
  Box,
  Grid,
  Button,
  styled,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
// styles
import "./../../styles/contact-us-section.css";
// ifrme
import Iframe from "react-iframe";
// animation
import { Fade } from "react-reveal";
// Modal
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const ContactUsSection = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [modal, setModal] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const SumbitInquiry = async () => {
    setLoading(true);
    if (name === "") {
      setError("Name is required!");
      setLoading(false);
      setTimeout(() => {
        setError("");
      }, 3000);
    } else if (
      email === "" ||
      !email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setError("Email is required!");
      setLoading(false);
      setTimeout(() => {
        setError("");
      }, 3000);
    } else if (phoneNumber === "") {
      setError("Phone mumber is required!");
      setLoading(false);
      setTimeout(() => {
        setError("");
      }, 3000);
    } else if (subject === "") {
      setError("Subject is required!");
      setLoading(false);
      setTimeout(() => {
        setError("");
      }, 3000);
    } else if (message === "") {
      setError("Message is required!");
      setLoading(false);
      setTimeout(() => {
        setError("");
      }, 3000);
    } else {
      let data = {
        name: name,
        email: email,
        phoneNumber: phoneNumber,
        subject: subject,
        message: message,
      };
      await fetch("https://nodmailer-server.herokuapp.com/route/sendemail", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          from: `"GMITS TECH Contact" <${data.name}>`,
          to: "gmitstech@gmail.com",
          subject: subject,
          html: `
          <div>
          <h1>Informations</h1>
          <ul>
          <li>Full Name : ${data.name}</li>
          <li>Email : ${data.email}</li>
          <li>Mobile Number : ${data.phoneNumber}</li>
          </ul>
          <h1>Subject: ${data.subject} </h1>
          <p>Message : ${data.message} </p>
          </div>
          `,
        }),
      })
        .then((responece) => responece.json())
        .then((res) => {
          setModal(true);
          setLoading(false);
          resetForm();
        })
        .catch((err) => {
          setLoading(false);
          setError(err);
          setTimeout(() => {
            setError("");
          }, 3000);
        });
    }
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setPhoneNumber("");
    setSubject("");
    setMessage("");
  };
  return (
    <div className="contact-us-section-container">
      <Fade bottom delay={400}>
        <h1 className="contact-us-section-heading">CONTACT US</h1>
        <h1 className="contact-us-section-heading-2">
          Got A Question? <span>Contact Us</span>
        </h1>
      </Fade>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={5}>
          <Grid item xl={2} lg={1} md={1} sm={12} xs={12}></Grid>
          <Grid item xl={4} lg={4} md={5} sm={6} xs={12}>
            <div className="contact-section-map-main">
              <div className="contact-section-map">
                <Iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7229.550454137204!2d55.219352!3d25.041701!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb6dbc77050c384b5!2sGMITS%20TECH%20LLC!5e0!3m2!1sen!2sus!4v1638029515287!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                />
              </div>
            </div>
          </Grid>
          <Grid item xl={4} lg={6} md={5} sm={6} xs={12}>
            <Fade bottom delay={400}>
              <input
                type="text"
                name="fullName"
                placeholder="Name"
                className="contact-input"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="contact-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
              />
              <input
                type="number"
                name="phone"
                placeholder="Phone"
                className="contact-input"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <input
                type="text"
                name="subject"
                placeholder="Subject"
                className="contact-input"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <textarea
                placeholder="Type your message here..."
                className="contact-textarea"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <p className="error">{error}</p>
              <Button
                type="text"
                fullWidth={true}
                className="contact-form-submit-button"
                onClick={SumbitInquiry}
                disabled={loading}
              >
                {loading ? "Loading ..." : "Sumbit Inquiry "}
              </Button>
            </Fade>
          </Grid>
          <Grid item xl={2} lg={1} md={1} sm={12} xs={12}></Grid>
        </Grid>
      </Box>
      <BootstrapDialog
        onClose={() => setModal(false)}
        aria-labelledby="customized-dialog-title"
        open={modal}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <div>
              <h1 id="modal-heading">You got it!</h1>
              <p id="modal-message">
              Thank you for contacting us, we have received your inquiry. Our sales team with contact you within 48 hour
              </p>
            </div>
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
export default ContactUsSection;
