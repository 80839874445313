import React, { useEffect } from "react";
// layout
import Layout from "../../../layout";
// components
import {
  Breadcrumbs,
  StepCard,
  GetInTouch,
  ContactUsSection,
} from "./../../../components";
// material ui
import { Box, Grid } from "@mui/material";
// images
import { SeoImage,SEOHeader } from "./../../../assets/images";
// animation
import { Fade } from "react-reveal";
// icons
import {
  SeoWhiteIcon2,
  OfflineSeoIcon,
  TechnicalSeoIcon,
} from "./../../../assets/icons";
// styles
import "./../../../styles/seo.css";
const Seo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const CardList = [
    {
      icon: SeoWhiteIcon2,
      title: "Online SEO Tools",
      description: "Title tags, Meta tags, URL structure, Site maps",
    },
    {
      icon: OfflineSeoIcon,
      title: "Offline SEO Tools",
      description: "Google WebMaster, Local search submission, Google ads",
    },
    {
      icon: TechnicalSeoIcon,
      title: "TECHNICAL SEO",
      description: "Google Analytics, Google Keyword Analyzer, WooRank, Moz",
    },
  ];
  return (
    <Layout>
      <Breadcrumbs heading="Search engine optimization (SEO)" show={true} image={SEOHeader}/>
      <div className="pages-section">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
            <div className="pages-section-data-main">
              <Fade bottom delay={400}>
                <h1 className="pages-section-heading">
                  BEST & AFFORDABLE SEO COMPANY
                </h1>
              </Fade>
              <Fade bottom delay={800}>
                <p className="pages-section-description">
                  GMITS is one of the best and affordable SEO Company in Dubai,
                  UAE & Indonesia. Search Engine Optimization helps you get 60%
                  more leads that 100 percent converts into ROIs. We provide On
                  page & Off page activities to rank your website on the first
                  page on Google Search Engine Results Contact us now to
                  discover how to get your website ranked on the first page of
                  Google Search Result Pages (SERP)
                </p>
              </Fade>
              </div>
            </Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <Fade bottom delay={1200}>
              <div className="pages-section-image-main">
                <img src={SeoImage} alt="SEO" className="pages-section-image" />
                </div>
              </Fade>
            </Grid>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Box>
      </div>
      <StepCard
        heading="SEARCH ENGINE OPTIMIZATION TOOLS"
        card={3}
        subHeading=""
        subHeading2=""
        subHeading3=""
        description="Companies Doing Digital Marketing, I.E. Search Engine Optimization, Digital Advertising, Social Media Management, Generate On Average 60% More Leads Than Those Depending Only On Traditional Outbound Marketing. We Help You To Grow Your Business By The Use Of Digital Marketing Successfully."
        CardList={CardList}
      />
      <div className="seo-section">
        <h1 className="seo-section-heading">SEO ACTIVITY DETAILS</h1>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <ul className="seo-section-list">
                <li>Submit Sitemap To Google</li>
                <li>Create Google Analytics Account To Monitor The Traffic.</li>
                <li>
                  Create MOZ And Google AdWord Account To Research Keywords.
                </li>
                <li>Update Meta Title Tag On All Pages.</li>
                <li>Update Image Alt Tag On All Pages.</li>
              </ul>
            </Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <ul className="seo-section-list">
                <li>
                  Create Google Webmaster Account And Check Errors On Daily
                  Basis And Resolve.
                </li>
                <li>
                  Send Monthly Report Of Traffic Over Website And SEO Progress
                  (If Needed).
                </li>
                <li>
                  Edit Website Pages To Use The Actual Keyword On Web Pages.
                </li>
                <li>Update Meta Description Tag On All Pages.</li>
                <li>Update Related Links On All Pages For One Month.</li>
              </ul>
            </Grid>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Box>
      </div>
      <GetInTouch />
      <ContactUsSection />
    </Layout>
  );
};
export default Seo;
