import React from "react";
// slider
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// styles
import "./../../styles/clients.css";
//  images
import {
  ClientImage1,
  ClientImage2,
  ClientImage3,
  ClientImage4,
  ClientImage5,
  ClientImage6,
  ClientImage7,
  ClientImage8,
  ClientImage9,
  ClientImage10,
  ClientImage11,
  ClientImage12,
} from "./../../assets/images";
// material ui
import { Box, Grid } from "@mui/material";
const Clients = (props) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1800 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 1800, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3  ,
      removeArrowOnDeviceType: "tablet",
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const List = [
    {
      logo: ClientImage1,
    },
    {
      logo: ClientImage2,
    },

    {
      logo: ClientImage3,
    },
    {
      logo: ClientImage4,
    },
    {
      logo: ClientImage5,
    },
    {
      logo: ClientImage6,
    },
    {
      logo: ClientImage7,
    },
    {
      logo: ClientImage8,
    },
    {
      logo: ClientImage9,
    },

    {
      logo: ClientImage10,
    },
    {
      logo: ClientImage11,
    },
    {
      logo: ClientImage12,
    },
  ];
  return (
    <div className="clients-container">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          <Grid item xl={8} lg={10} md={10} sm={10} xs={12}>
            <Carousel
              responsive={responsive}
              draggable={true}
              swipeable={true}
              removeArrowOnDeviceType="mobile"
              showDots={false}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              arrows={false}
              renderDotsOutside={false}
              autoPlay={props.deviceType !== "mobile" ? true : false}
              containerClass="carousel-container"
              deviceType={props.deviceType}
              dotListClass="custom-dot-list-style"
              className="_carousel"

            >
              {List.map((v, i) => {
                return (
                  <div className="clients-slider" key={i}>
                    <img
                      src={v.logo}
                      alt="Logo"
                      className="clients-slider-logo"
                    />
                  </div>
                );
              })}
            </Carousel>
          </Grid>
          <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default Clients;
