import React from "react";
//styles
import "./../../styles/footer.css";
// images
import {
  Logo2,
  UAEFlag,
  IndonesiaFlag,
  IraqFlag,
  IndiaFlag,
  PakistanFlag,
} from "./../../assets/images";
// material ui
import { Box, Grid, Button } from "@mui/material";
// icons
import { IoCall } from "react-icons/io5";
import { HiOutlineMail } from "react-icons/hi";
import { FaHome } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";
// router
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer-container">
      <div className="footer-logo-main">
        <Button
          variant="text"
          disableRipple={true}
          className="footer-logo-button"
          onClick={() => navigate("/")}
        >
          <img src={Logo2} alt="Logo" className="footer-logo" />
        </Button>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <div className="footer-section">
              <div className="footer-header">
                <img src={UAEFlag} alt="UAE" className="footer-country-flag" />
                <h1 className="footer-country-name">UAE</h1>
              </div>
              <Button
                variant="text"
                href="mailto:info@gmitstech.com"
                className="footer-button"
                disableRipple={true}
              >
                <HiOutlineMail className="footer-button-icon" />
                info@gmitstech.com
              </Button>
              <Button
                variant="text"
                className="footer-button-2"
                disableRipple={true}
                onClick={() => navigate("/")}
              >
                <FaHome className="footer-button-icon" />
                Al Jazirah Al Hamra<br />
                United Arab Emirates
              </Button>
              <Button
                variant="text"
                href="tel: +971 5 0 987 3721"
                className="footer-button-2"
                disableRipple={true}
              >
                <IoCall className="footer-button-icon" /> +971 5 0 987 3721
              </Button>
              <Button
                variant="text"
                href="tel: +971 4 236 7319"
                className="footer-button-2"
                disableRipple={true}
              >
                <IoCall className="footer-button-icon" /> +971 4 236 7319
              </Button>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <div className="footer-section">
              <div className="footer-header">
                <img
                  src={IndonesiaFlag}
                  alt="Indonesia"
                  className="footer-country-flag"
                />
                <h1 className="footer-country-name">JAKARTA, INDONESIA</h1>
              </div>
              <Button
                variant="text"
                href="mailto:info@gmitstech.com"
                className="footer-button"
                disableRipple={true}
              >
                <HiOutlineMail className="footer-button-icon" />
                info@gmitstech.com
              </Button>
              <Button
                variant="text"
                className="footer-button-2"
                disableRipple={true}
                onClick={() => navigate("/")}
              >
                <FaHome className="footer-button-icon" />
                WISMA BNI 46, 45TH FLOOR
              </Button>
              <Button
                variant="text"
                href="tel: +62 8 2258 534002"
                className="footer-button-2"
                disableRipple={true}
              >
                <IoCall className="footer-button-icon" /> +62 8 2258 534002
              </Button>
              <Button
                variant="text"
                target="_blank"
                href="https://www.shortlyst.ai/"
                className="footer-web-link-button"
                disableRipple={true}
              >
                <BiWorld className="footer-button-icon" /> Shortlyst
              </Button>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <div className="footer-section">
              <div className="footer-header">
                <img
                  src={IraqFlag}
                  alt="Iraq"
                  className="footer-country-flag"
                />
                <h1 className="footer-country-name">Iraq</h1>
              </div>
              <Button
                variant="text"
                href="mailto:info@gmitstech.com"
                className="footer-button"
                disableRipple={true}
              >
                <HiOutlineMail className="footer-button-icon" />
                info@gmitstech.com
              </Button>
              <Button
                variant="text"
                className="footer-button-2"
                disableRipple={true}
                onClick={() => navigate("/")}
              >
                <FaHome className="footer-button-icon" />
                BGD Tower , Iraq
              </Button>
              <Button
                variant="text"
                href="tel:+9647816092428"
                className="footer-button-2"
                disableRipple={true}
              >
                <IoCall className="footer-button-icon" /> +96 4 7816 092428
              </Button>
              <Button
                variant="text"
                className="footer-web-link-button"
                disableRipple={true}
                onClick={() => navigate("/")}
              >
                <BiWorld className="footer-button-icon" /> gmitstech
              </Button>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <div className="footer-section">
              <div className="footer-header">
                <img
                  src={IndiaFlag}
                  alt="India"
                  className="footer-country-flag"
                />
                <h1 className="footer-country-name">India</h1>
              </div>
              <Button
                variant="text"
                href="mailto:info@gmitstech.com"
                className="footer-button"
                disableRipple={true}
              >
                <HiOutlineMail className="footer-button-icon" />
                info@gmitstech.com
              </Button>
              <Button
                variant="text"
                className="footer-button-2"
                disableRipple={true}
                onClick={() => navigate("/")}
              >
                <FaHome className="footer-button-icon" />
                Surat, India
              </Button>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <div className="footer-section">
              <div className="footer-header">
                <img
                  src={PakistanFlag}
                  alt="Pakistan"
                  className="footer-country-flag"
                />
                <h1 className="footer-country-name">Pakistan</h1>
              </div>
              <Button
                variant="text"
                href="mailto:info@gmitstech.com"
                className="footer-button"
                disableRipple={true}
              >
                <HiOutlineMail className="footer-button-icon" />
                info@gmitstech.com
              </Button>
              <Button
                variant="text"
                className="footer-button-2"
                disableRipple={true}
                onClick={() => navigate("/")}
              >
                <FaHome className="footer-button-icon" />
                Karachi , Pakistan
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default Footer;
