import React, { useState } from "react";
// material ui
import { Box, Grid, Button } from "@mui/material";
// icons
import { AiFillCaretDown } from "react-icons/ai";
// styles
import "./../../styles/tabs.css";
// animation
import { Fade } from "react-reveal";
const Tabs = (props) => {
  const [tab, setTab] = useState(0);
  return (
    <div className="tabs-container">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          <Grid item xl={8} lg={10} md={10} sm={10} xs={12}>
            <Fade bottom delay={400}>
              <h1 className="tabs-heading">
                {props.heading}
                <span>{props.heading2}</span>
              </h1>
              <p className="tabs-header-title">{props.subHeading}</p>
            </Fade>
            <div className="tabs-header">
              {props.TabsList.map((tabsValue, tabsIndex) => {
                return (
                  <Button
                    key={tabsIndex}
                    className={
                      tab === tabsIndex
                        ? "tabs-header-active-button"
                        : "tabs-header-button"
                    }
                    onClick={() => setTab(tabsIndex)}
                  >
                    <Fade bottom delay={tabsIndex * 400}>
                      <img
                        src={
                          tab === tabsIndex
                            ? tabsValue.activeIcon
                            : tabsValue.icon
                        }
                        alt="icon"
                        className="tabs-header-icon"
                      />
                      <p
                        className={
                          tab === tabsIndex ? "tabs-active-title" : "tabs-title"
                        }
                      >
                        {tabsValue.title}
                      </p>
                      <p
                        className={
                          tab === tabsIndex ? "tabs-active-title" : "tabs-title"
                        }
                      >
                        {tabsValue.title2}
                      </p>
                    </Fade>
                    <AiFillCaretDown
                      className={
                        tab === tabsIndex
                          ? "tabs-down-active-icon"
                          : "tabs-down-icon"
                      }
                    />
                  </Button>
                );
              })}
            </div>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={5}>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <div className="tabs-details-show">
                    <Fade bottom delay={400}>
                      <h1 className="tabs-heading-2">
                        {props.TabsList[tab].heading}
                      </h1>
                    </Fade>
                    <Fade bottom delay={800}>
                      <p className="tabs-description">
                        {props.TabsList[tab].description}
                      </p>
                    </Fade>
                  </div>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <div className="tabs-image-main">
                    <Fade bottom delay={1200}>
                      <img
                        src={props.TabsList[tab].image}
                        className="tabs-image"
                      />
                    </Fade>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default Tabs;
