import React, { useEffect } from "react";
// layout
import Layout from "../../../layout";
// components
import {
  Breadcrumbs,
  StepCard,
  GetInTouch,
  ContactUsSection,
} from "./../../../components";
// material ui
import { Box, Grid } from "@mui/material";
// images
import {
  WebHostingImage,
  WebSostingImage2,
  WebHostingHeader
} from "./../../../assets/images";
// animation
import { Fade } from "react-reveal";
// icons
import {
  CategoryIcon1,
  CategoryIcon2,
  CategoryIcon3,
} from "./../../../assets/icons";
const WebHosting = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const CardList = [
    {
      icon: CategoryIcon1,
      title: "Category 1",
      description:
        "1 Static website of 5 pages 2GB storage upgradable 5 Email address 1 database",
    },
    {
      icon: CategoryIcon2,
      title: "Category 2",
      description:
        "3 Static Websites 6GB storage15 Email addresses 50GB Monthly Bandwidth 5 databases",
    },
    {
      icon: CategoryIcon3,
      title: "Category 3",
      description:
        "Dynamic websites 5GB storage Email addresses 10GB Monthly Bandwidth 1 database",
    },
  ];
  return (
    <Layout>
       <Breadcrumbs heading="Web Hosting" show={true} image={WebHostingHeader} />
      <div className="pages-section">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
            <div className="pages-section-data-main">
              <Fade bottom delay={400}>
                <h1 className="pages-section-heading">
                  LINUX/WINDOWS WEBSITE HOSTING
                </h1>
              </Fade>
              <Fade bottom delay={800}>
                <p className="pages-section-description">
                  We are providing you Linux/Windows, Dedicated, Cloud Web
                  Hosting in Dubai, UAE. We establish close and long-lasting
                  relationships with our customers & give the best quality
                  services.
                  <br />
                  We establish close and long term relationship with our
                  customers and provide best quality services.
                </p>
              </Fade>
              </div>
            </Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <Fade bottom delay={1200}>
              <div className="pages-section-image-main">
                <img
                  src={WebHostingImage}
                  alt="Web Hosting"
                  className="pages-section-image"
                />
                </div>
              </Fade>
            </Grid>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Box>
      </div>
      <StepCard
        heading="OUR WEBSITE HOSTING CATEGORIES"
        subHeading=""
        subHeading2=""
        subHeading3=""
        card={3}
        description=""
        CardList={CardList}
      />
      <div className="pages-section">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
            <div className="pages-section-data-main">
              <Fade bottom delay={400}>
                <h1 className="pages-section-heading">
                  MORE ABOUT DEDICATED WEB HOSTING
                </h1>
              </Fade>
              <Fade bottom delay={800}>
                <p className="pages-section-description">
                  A Website Is A Root Of Your Business In A Way, More
                  Interactive And Attractive The Website, More Will Be People
                  Finding Interest In Your Business And In Turn More Will Your
                  Business Grow. In Today’s Modern World Nobody Cares About The
                  Verbal Commitments. So You Have To Present Your Business One
                  To One To Your Clients, And What Better Idea Then A Smart And
                  Attractive Website Depicting Your Business In A Best Possible
                  Way.
                  <br />
                  Depending On Your Scale Of Industry You Can Choose Best
                  Possible Category For Yourself. We Not Only Provide Support
                  And Commitment To Our Clients 24*7, We Also Believe In Serving
                  Our Clients At A Price That Is Easy For Them To Accept.
                </p>
              </Fade>
              </div>
            </Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <Fade bottom delay={1200}>
              <div className="pages-section-image-main">
                <img
                  src={WebSostingImage2}
                  alt="Web Hosting"
                  className="pages-section-image"
                />
                </div>
              </Fade>
            </Grid>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Box>
      </div>
      <GetInTouch />
      <ContactUsSection />
    </Layout>
  );
};
export default WebHosting;
