import React from "react";
// styles
import "./../../styles/navbar.css";
// material ui
import { Box, Grid, Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
// icons
import { TbChevronDown } from "react-icons/tb";
import { BiMenu } from "react-icons/bi";
import {
  DigitalMarketingNavIcon,
  SeoNavIcon,
  SocialMediaNavIcon,
} from "./../../assets/icons";
// images
import { Logo } from "./../../assets/images";
// components
import { Drawer } from "./../";
// router
import { useLocation, useNavigate } from "react-router-dom";
// svg
import {
  ArtificialIntelligence,
  MobileAppDevelopment,
  WebsiteDevelopment,
  WebHosting,
} from "./../../assets/svg";
const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [drawer, setDrawer] = React.useState(false);
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const ServicesList = [
    {
      title: "Artificial intelligence",
      icon: ArtificialIntelligence,
      path: "/services/artificial-intelligence",
      bgColor: "rgb(37, 211, 102)",
    },
    {
      title: "Mobile App Development",
      icon: MobileAppDevelopment,
      path: "/services/mobile-app-development",
      bgColor: "rgb(29, 161, 242)",
    },
    {
      title: "Website Development",
      icon: WebsiteDevelopment,
      path: "/services/website-development",
      bgColor: "rgb(0, 119, 181)",
    },
    {
      title: "Digital Marketing",
      icon: DigitalMarketingNavIcon,
      path: "/services/digital-marketing",
      bgColor: "#772add",
    },
    {
      title: "SEO",
      icon: SeoNavIcon,
      path: "/services/seo",
      bgColor: "#2ad4dd",
    },
    {
      title: "Social Media Marketing",
      icon: SocialMediaNavIcon,
      path: "/services/social-media-marketing",
      bgColor: "#0085ff",
    },
    {
      title: "Web Hosting",
      icon: WebHosting,
      path: "/services/web-hosting",
      bgColor: "rgb(255, 0, 0)",
    },
  ];
  const drawerHandler = () => {
    setDrawer(!drawer);
  };
  return (
    <div className="navbar-container">
      <Drawer drawerHandler={drawerHandler} isDrawerOpen={drawer} />
      {matches ? (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xl={2} lg={1} md={1} sm={0} xs={0}></Grid>
            <Grid item xl={8} lg={10} md={10} sm={12} xs={12}>
              <div className="navbar-inner-container">
                <Button
                  variant="text"
                  disableRipple={true}
                  className="navbar-logo-button"
                  onClick={() => navigate("/")}
                >
                  <img src={Logo} alt="Logo" className="navbar-logo" />
                </Button>
                <div className="navbar-2nd-section">
                  <Button
                    variant="text"
                    disableRipple={true}
                    className={
                      path === "/" ? "navbar-active-item" : "navbar-item"
                    }
                    onClick={() => navigate("/")}
                  >
                    Home
                  </Button>
                  <Button
                    variant="text"
                    disableRipple={true}
                    className={
                      path === "/about-us"
                        ? "navbar-active-item"
                        : "navbar-item"
                    }
                    onClick={() => navigate("/about-us")}
                  >
                    About Us
                  </Button>
                  <div className="navbar-dropdown">
                    <Button
                      variant="text"
                      disableRipple={true}
                      className={
                        path === "/services/artificial-intelligence" ||
                        path === "/services/mobile-app-development" ||
                        path === "/services/website-development" ||
                        path === "/services/digital-marketing" ||
                        path === "/services/seo" ||
                        path === "/services/social-media-marketing" ||
                        path === "/services/web-hosting"
                          ? "navbar-active-item"
                          : "navbar-item"
                      }
                    >
                      Services
                      <TbChevronDown className="navbar-services-down-icon" />
                    </Button>
                    <div className="navbar-dropdown-content">
                      <div className="navbar-dropdown-content-items-main">
                        {ServicesList.map((servicesValue, servicsIndex) => {
                          return (
                            <Button
                              variant="text"
                              disableRipple={true}
                              className={
                                path === servicesValue.path
                                  ? "navbar-dropdown-active-item"
                                  : "navbar-dropdown-item"
                              }
                              key={servicsIndex}
                              onClick={() => navigate(servicesValue.path)}
                            >
                              <div
                                style={{
                                  backgroundColor: servicesValue.bgColor,
                                }}
                                className="navbar-dropdown-icon"
                              >
                                <img
                                  src={servicesValue.icon}
                                  alt={"icon"}
                                  height="20px"
                                  width={"20px"}
                                />
                              </div>
                              {servicesValue.title}
                            </Button>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <Button
                    variant="text"
                    disableRipple={true}
                    className={
                      path === "/success-story"
                        ? "navbar-active-item"
                        : "navbar-item"
                    }
                    onClick={() => navigate("/success-story")}
                  >
                    Success Story
                  </Button>
                  <Button
                    variant="text"
                    disableRipple={true}
                    className={
                      path === "/contact-us"
                        ? "navbar-active-item"
                        : "navbar-item"
                    }
                    onClick={() => navigate("/contact-us")}
                  >
                    Contact Us
                  </Button>
                </div>
                <Button
                  variant="text"
                  disableRipple={true}
                  className="navbar-get-quote-button"
                  onClick={() => navigate("/contact-us")}
                >
                  get quote
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid item xl={2} lg={1} md={1} sm={0} xs={0}></Grid>
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="navbar-mobile-view">
                <Button
                  variant="text"
                  disableRipple={true}
                  className="navbar-mobile-view-logo-button"
                >
                  <img
                    src={Logo}
                    alt="logo"
                    className="navbar-mobile-view-logo"
                  />
                </Button>
                <Button
                  variant="text"
                  disableRipple={true}
                  className="navbar-drawer-button"
                  onClick={drawerHandler}
                >
                  <BiMenu />
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};
export default Navbar;
