import React from "react";
// styles
import "./../../styles/get-in-touch.css";
// material ui
import { Box, Grid, Button } from "@mui/material";
// router
import { useNavigate } from "react-router-dom";
// animation
import { Fade } from "react-reveal";
const GetInTouch = () => {
  const navigate = useNavigate();
  return (
    <div className="get-in-touch-container">
      <div className="get-in-touch-inner-container">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={8} lg={10} md={10} sm={10} xs={12}>
              <Fade bottom delay={400}>
                <h1 className="get-in-touch-heading">
                  GET HANDS ON EXPERIENCE WITH OUR SERVICES
                </h1>
                <p className="get-in-touch-description">
                  Expand your business by getting hands on experience with our
                  services Experience Life-long satisfactory results and improve
                  your sales funnel that converts into desired ROI and brings
                  life to your business
                </p>
                <div className="get-in-touch-button-main">
                  <Button
                    type="text"
                    onClick={() => navigate("/contact-us")}
                    className="get-in-touch-button"
                  >
                    get in touch
                  </Button>
                </div>
              </Fade>
            </Grid>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};
export default GetInTouch;
