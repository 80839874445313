import React, { useState } from "react";
// material ui
import { Button, Drawer } from "@mui/material";
// images
import { Logo } from "./../../assets/images";
// router
import { useLocation, useNavigate } from "react-router-dom";
// styles
import "./../../styles/drawer.css";
// icons
import { RiCloseFill } from "react-icons/ri";
import { TbChevronDown } from "react-icons/tb";
import {
  HomeIcon,
  HomeActiveIcon,
  AboutUsIcon,
  AboutUsActiveIcon,
  ServicesIcon,
  ServicesActiveIcon,
  SuccessStoryIcon,
  SuccessStoryActiveIcon,
  ContactUsIcon,
  ContactUsActiveIcon,
  DigitalMarketingNavIcon,
  SeoNavIcon,
  SocialMediaNavIcon,
} from "./../../assets/icons";
// svg
import {
  ArtificialIntelligence,
  MobileAppDevelopment,
  WebsiteDevelopment,
  WebHosting,
} from "./../../assets/svg";
const drawerWidth = 340;
const DrawerComponent = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [servicesDropdwon, setServicesDropdown] = useState(false);
  const ServicesList = [
    {
      title: "Artificial intelligence",
      icon: ArtificialIntelligence,
      path: "/services/artificial-intelligence",
      bgColor: "rgb(37, 211, 102)",
    },
    {
      title: "Mobile App Development",
      icon: MobileAppDevelopment,
      path: "/services/mobile-app-development",
      bgColor: "rgb(29, 161, 242)",
    },
    {
      title: "Website Development",
      icon: WebsiteDevelopment,
      path: "/services/website-development",
      bgColor: "rgb(0, 119, 181)",
    },
    {
      title: "Digital Marketing",
      icon: DigitalMarketingNavIcon,
      path: "/services/digital-marketing",
      bgColor: "#772add",
    },
    {
      title: "SEO",
      icon: SeoNavIcon,
      path: "/services/seo",
      bgColor: "#2ad4dd",
    },
    {
      title: "Social Media Marketing",
      icon: SocialMediaNavIcon,
      path: "/services/social-media-marketing",
      bgColor: "#0085ff",
    },
    {
      title: "Web Hosting",
      icon: WebHosting,
      path: "/services/web-hosting",
      bgColor: "rgb(255, 0, 0)",
    },
  ];
  return (
    <Drawer
      anchor="left"
      open={props.isDrawerOpen}
      onClose={props.drawerHandler}
      sx={{
        "& .MuiDrawer-paper": { width: drawerWidth },
      }}
    >
      <div className="drawer-header">
        <img src={Logo} alt="logo" className="drawer-logo" />
        <Button
          variant="text"
          disableRipple={true}
          onClick={props.drawerHandler}
          className="drawer-close-button"
        >
          <RiCloseFill />
        </Button>
      </div>
      <div className="drawer-container">
        <Button
          variant="text"
          className={path === "/" ? "drawer-active-item" : "drawer-item"}
          onClick={() => navigate("/")}
        >
          {path === "/" ? (
            <img src={HomeActiveIcon} alt="icon" className="drawer-item-icon" />
          ) : (
            <img src={HomeIcon} alt="icon" className="drawer-item-icon" />
          )}
          Home
        </Button>
        <Button
          variant="text"
          className={
            path === "/about-us" ? "drawer-active-item" : "drawer-item"
          }
          onClick={() => navigate("/about-us")}
        >
          {path === "/about-us" ? (
            <img
              src={AboutUsActiveIcon}
              alt="icon"
              className="drawer-item-icon"
            />
          ) : (
            <img src={AboutUsIcon} alt="icon" className="drawer-item-icon" />
          )}
          About Us
        </Button>
        <Button
          variant="text"
          className={
            path === "/services/artificial-intelligence" ||
            path === "/services/mobile-app-development" ||
            path === "/services/website-development" ||
            path === "/services/digital-marketing" ||
            path === "/services/seo" ||
            path === "/services/social-media-marketing" ||
            path === "/services/web-hosting"
              ? "drawer-active-item"
              : "drawer-item"
          }
          onClick={() => setServicesDropdown(!servicesDropdwon)}
        >
          <div className="drawer-services-button">
            <div>
              {path === "/services/artificial-intelligence" ||
              path === "/services/mobile-app-development" ||
              path === "/services/website-development" ||
              path === "/services/digital-marketing" ||
              path === "/services/seo" ||
              path === "/services/social-media-marketing" ||
              path === "/services/web-hosting" ? (
                <img
                  src={ServicesActiveIcon}
                  alt="icon"
                  className="drawer-item-icon"
                />
              ) : (
                <img
                  src={ServicesIcon}
                  alt="icon"
                  className="drawer-item-icon"
                />
              )}
              Services
            </div>
            <TbChevronDown className="drawer-services-down-arrow" />
          </div>
        </Button>
        {servicesDropdwon && (
          <div
            className={
              path === "/services/artificial-intelligence" ||
              path === "/services/mobile-app-development" ||
              path === "/services/website-development" ||
              path === "/services/digital-marketing" ||
              path === "/services/seo" ||
              path === "/services/social-media-marketing" ||
              path === "/services/web-hosting"
                ? "drawer-services-list-active-container"
                : "drawer-services-list-container"
            }
          >
            {ServicesList.map((servicesValue, servicsIndex) => {
              return (
                <Button
                  variant="text"
                  className={
                    path === servicesValue.path
                      ? "drawer-dropdown-active-item"
                      : "drawer-dropdown-item"
                  }
                  key={servicsIndex}
                  onClick={() => navigate(servicesValue.path)}
                >
                  <div
                    style={{
                      backgroundColor: servicesValue.bgColor,
                    }}
                    className="navbar-dropdown-icon"
                  >
                    <img
                      src={servicesValue.icon}
                      alt={"icon"}
                      height="20px"
                      width={"20px"}
                    />
                  </div>
                  {servicesValue.title}
                </Button>
              );
            })}
          </div>
        )}
        <Button
          variant="text"
          className={
            path === "/success-story" ? "drawer-active-item" : "drawer-item"
          }
          onClick={() => navigate("/success-story")}
        >
          {path === "/success-story" ? (
            <img
              src={SuccessStoryActiveIcon}
              alt="icon"
              className="drawer-item-icon"
            />
          ) : (
            <img
              src={SuccessStoryIcon}
              alt="icon"
              className="drawer-item-icon"
            />
          )}
          Success Story
        </Button>
        <Button
          variant="text"
          className={
            path === "/contact-us" ? "drawer-active-item" : "drawer-item"
          }
          onClick={() => navigate("/contact-us")}
        >
          {path === "/contact-us" ? (
            <img
              src={ContactUsActiveIcon}
              alt="icon"
              className="drawer-item-icon"
            />
          ) : (
            <img src={ContactUsIcon} alt="icon" className="drawer-item-icon" />
          )}
          Contact Us
        </Button>
        <Button
          variant="text"
          disableRipple={true}
          className="drawer-get-quote-button"
          onClick={() => navigate("/contact-us")}
        >
          get quote
        </Button>
      </div>
    </Drawer>
  );
};

export default DrawerComponent;
