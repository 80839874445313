import React, { useEffect } from "react";
// layout
import Layout from "../../../layout";
// components
import {
  Breadcrumbs,
  StepCard,
  GetInTouch,
  ContactUsSection,
} from "./../../../components";
// material ui
import { Box, Grid } from "@mui/material";
// images
import {
  DigitalMarketingImage,
  DigitalMarketingImage2,
  DigitalMarketingHeader,
} from "./../../../assets/images";
// animation
import { Fade } from "react-reveal";
// icons
import {
  SearchIcon,
  DisplayIcon,
  ShoppingIcon,
  VideoIcon,
  SmartIcon,
  DiscoverIcon,
} from "./../../../assets/icons";
const DigitalMarketing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const CardList = [
    {
      icon: SearchIcon,
      title: "Search",
      description:
        "Reach Customers who are interested in your products & services with text ads",
    },
    {
      icon: DisplayIcon,
      title: "Display",
      description: "Run different kinds of ads across the websites",
    },
    {
      icon: ShoppingIcon,
      title: "Shopping",
      description: "Promote your products with shopping ads",
    },
    {
      icon: VideoIcon,
      title: "Video",
      description: "Reach and engage viewers on you Tube & across the web",
    },
    {
      icon: SmartIcon,
      title: "Smart",
      description:
        "Reach your business goals with automated ads on Google and across the web",
    },
    {
      icon: DiscoverIcon,
      title: "Discover",
      description:
        "Generate leads with visually rich personalized ads that show across YouTube, Gmail and more",
    },
  ];
  return (
    <Layout>
      <Breadcrumbs
        heading="Digital Marketing"
        show={true}
        image={DigitalMarketingHeader}
      />
      <div className="pages-section">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <div className="pages-section-data-main">
                <Fade bottom delay={400}>
                  <h1 className="pages-section-heading">
                    BEST & AFFORDABLE DIGITAL MARKETING
                  </h1>
                </Fade>
                <Fade bottom delay={800}>
                  <p className="pages-section-description">
                    We are the Best In Digital Marketing Agencies in Dubai , UAE
                    & Indonesia Similarly, we specialize in Digital Marketing &
                    LEAD GENERATION. There are various ways of generating leads
                    through digital marketing.
                    <br />
                    Above all, Google AdWords is one of the most popular
                    platform known for generating qualified and genuine leads
                    which converts into high ROI. The top most companies rely on
                    Google to achieve their monthly revenue.
                    <br />
                    The best thing about Google ads is, it helps the businesses
                    to reach the right customers at the right time. We create
                    and manage campaigns and help our business partners by
                    generating qualified leads that achieves sales target goal.
                    <br />
                    Advertising on one of the best digital channels like Google
                    can obtain good returns from your spending.
                    <br />
                    In other words, GMITS helps clients to get a 100% return
                    from their PPC ads.
                  </p>
                </Fade>
              </div>
            </Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <Fade bottom delay={1200}>
                <div className="pages-section-image-main">
                  <img
                    src={DigitalMarketingImage}
                    alt="Digital Marketing"
                    className="pages-section-image"
                  />
                </div>
              </Fade>
            </Grid>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Box>
      </div>
      <StepCard
        heading="PLATFORMS WE USE FOR LEAD GENERATION"
        card={3}
        subHeading=""
        subHeading2=""
        subHeading3=""
        description="Advertising Is Key For Businesses To Increase Visibility And Get More Exposure Across Digital Platforms."
        CardList={CardList}
      />
      <div className="pages-section">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
            <div className="pages-section-data-main">
              <Fade bottom delay={400}>
                <h1 className="pages-section-heading">
                  OUR AREAS OF EXPERTISE IN DIGITALIZATION
                </h1>
              </Fade>
              <Fade bottom delay={800}>
                <p className="pages-section-description">
                  Companies Generate On Average 60% More Leads By Using Google
                  AdWords Than Those Depending Only On Traditional Outbound
                  Marketing.
                  <br />
                  Therefore, We Help Our Business Partners To Successfully Grow
                  Their Businesses By Using The Strategy That Performs Well On
                  Google Platform
                </p>
              </Fade>
              <Fade bottom delay={400}>
                <h1 className="pages-section-heading">
                  Our Marketing Strategies Covers:
                </h1>
              </Fade>
              <Fade bottom delay={800}>
                <p className="pages-section-description">
                  Inbound Marketing | Effective Website Design For Lead
                  Generation | Search Engine Optimization (SEO) | Social Media
                  Marketing |Google Webmaster | Google Analytics | Digital
                  Adverting (Pay-Per-Click) | Google AdWords | Facebook,
                  Instagram, And LinkedIn Ads | Email Marketing | Landing Pages
                  Designs Calls-To-Action | Blogging
                </p>
              </Fade>
              </div>
            </Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <Fade bottom delay={1200}>
              <div className="pages-section-image-main">
                <img
                  src={DigitalMarketingImage2}
                  alt="Digital Marketing"
                  className="pages-section-image"
                />
                </div>
              </Fade>
            </Grid>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Box>
      </div>
      <GetInTouch />
      <ContactUsSection />
    </Layout>
  );
};
export default DigitalMarketing;
