import React,{useEffect} from "react";
//styles
import "./App.css";
import './constant/main.css';
import './constant/main-media-query.css'
//router
import RouterNavigation from "./config/router";
// material ui
import { ThemeProvider, createTheme } from "@mui/material/styles";
const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Poppins",
      padding: "0px",
      margin:"0px"
    },
  },
});
const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <RouterNavigation />
    </ThemeProvider>
  );
};
export default App;
