import React, { useEffect } from "react";
// layout
import Layout from "../../layout";
import {
  Breadcrumbs,
  GetInTouch,
  ContactUsSection,
  Portfolio,
  Testimonials,
  Clients,
  TestimonialsVideo,
} from "./../../components";
// material ui
import { Box, Grid } from "@mui/material";
// images
import { SuccessStoryImage,SuccessStoryHeader } from "./../../assets/images";
// styles
import "./../../styles/success-story.css";
const SuccessStory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
     <Breadcrumbs heading="Success Story" image={SuccessStoryHeader}/>
      <div className="pages-section">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
            <div className="pages-section-data-main">
              <h1 className="pages-section-heading">
                GMITS | THE BEST & TRUSTED IT SOLUTION
              </h1>
              <p className="pages-section-description">
                GMITSTECH has earned reputation and respect from its client by
                delivering top-notch customized IT solutions. We have satisfied
                clients from all over the globe and we aim to support our
                clientele with best support and services
                <br />
                We believe in making strong connection with our valued business
                partners and long-lasting relation with them. We dedicate our
                success to our Business partners and their belief in us
                GMITSTECH has earned the reputation and respect
              </p>
              </div>
            </Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
            <div className="pages-section-image-main">
              <img
                src={SuccessStoryImage}
                alt="success storay"
                className="success-story-image"
              />
              </div>
            </Grid>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Box>
      </div>
      <Portfolio />
      <Testimonials />
      <Clients />
      <TestimonialsVideo />
      <GetInTouch />
      <ContactUsSection />
    </Layout>
  );
};
export default SuccessStory;
