import React, { useEffect } from "react";
// layout
import Layout from "../../../layout";
// components
import {
  Breadcrumbs,
  StepCard,
  GetInTouch,
  ContactUsSection,
} from "./../../../components";
// material ui
import { Box, Grid } from "@mui/material";
// images
import {
  SocialMediaMarketingImage,
  SocialMediaMarketingImage2,
  SocialMediaMarketingHeader
} from "./../../../assets/images";
// animation
import { Fade } from "react-reveal";
// icons
import {
  SocialMediaWhiteIcon,
  UserIcon,
  FrequencyIcon,
  TrackingIcon,
} from "./../../../assets/icons";
const SocialMediaMarketing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const CardList = [
    {
      icon: SocialMediaWhiteIcon,
      title: "Social Media Strategy",
      description:
        "SEO (Search Engine Optimization) helps you get 60% more leads. GMITS helps clients to get their website on first page on google search result pages.",
    },
    {
      icon: UserIcon,
      title: "Social Media Profiles",
      description:
        "Social media is one the top factor to show your activeness of business. GMITS helps target genuine follower base and convert them into loyal customers",
    },
    {
      icon: FrequencyIcon,
      title: "Frequency Posting",
      description:
        "Advertise on one of the best digital channels like google and get good returns from your spending. GMITS helps clients to get 100% return from their PPC.",
    },
    {
      icon: TrackingIcon,
      title: "Social Media Advertising/Tracking",
      description:
        "Provide education to your customers by publishing Blogs. GMITS has professional team helps you to return your customer by writing educational blogs.",
    },
  ];
  return (
    <Layout>
   <Breadcrumbs heading="Social Media Marketing" show={true} image={SocialMediaMarketingHeader}/>
      <div className="pages-section">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
            <div className="pages-section-data-main">
              <Fade bottom delay={400}>
                <h1 className="pages-section-heading">
                  SOCIAL MEDIA MARKETING
                </h1>
              </Fade>
              <Fade bottom delay={800}>
                <p className="pages-section-description">
                  GMITS provides best and low-cost Social Media Marketing in
                  Dubai, UAE & Indonesia. We have 5 years of hardcore experience
                  in handling Social Media Marketing.
                  <br />
                  Our objective is to help you gain and build an active
                  community on your business pages. We manage your accounts on
                  all social platforms and work on getting genuine organic
                  followers & awareness about your products \ services.
                  <br />
                  We have been successful in converting followers into lasting
                  customers for our business clients. We tend to use latest
                  trends and tools, i.e. Profile, Tracking, Publishing.
                  <br />
                  Our aim is to get global exposure for your business through
                  your social profiles. we have a track record of achieving
                  phenomenal global reach for our clients.
                </p>
              </Fade>
              </div>
            </Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <Fade bottom delay={1200}>
              <div className="pages-section-image-main">
                <img
                  src={SocialMediaMarketingImage}
                  alt="Social Media Marketing"
                  className="pages-section-image"
                />
                </div>
              </Fade>
            </Grid>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Box>
      </div>
      <StepCard
        heading="SOCIAL MEDIA TOOLS"
        subHeading=""
        subHeading2=""
        subHeading3=""
        description="Discover The Benefits By Choosing Our Services. Experience Life Long Satisfactory Impact On Your Business By Getting Into Long Term Partnership With Us."
        CardList={CardList}
      />
      <div className="pages-section">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
            <div className="pages-section-data-main">
              <Fade bottom delay={400}>
                <h1 className="pages-section-heading">OUR STRATEGY</h1>
              </Fade>
              <Fade bottom delay={800}>
                <p className="pages-section-description">
                  Digital Marketing refers to the strategy of gaining traffic
                  and attention through social media platforms. Digital
                  Marketing itself is a catch-all term for platforms which can
                  provide completely different social actions to increase
                  traffic on your website. In addition to followers which are
                  equally important. Example: Twitter is a social website
                  designed to share short messages or updates with your
                  followers. But Facebook allows you a unique manner of
                  approaching your target market. Every social platform is
                  exclusive and provides a unique method of touching your target
                  market.
                  <br />
                  That’s right! 90% of business owners and marketers claimed
                  that social media generated significant exposure for his or
                  her company, and that’s only one of its several blessings.
                  Social networks squarely measures as a part of each promoting
                  strategy, and therefore, the advantages of Social Media are
                  immeasurable.
                </p>
              </Fade>
              </div>
            </Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <Fade bottom delay={1200}>
              <div className="pages-section-image-main">
                <img
                  src={SocialMediaMarketingImage2}
                  alt="Social Media Marketing"
                  className="pages-section-image"
                />
                </div>
              </Fade>
            </Grid>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Box>
      </div>
      <GetInTouch />
      <ContactUsSection />
    </Layout>
  );
};
export default SocialMediaMarketing;
