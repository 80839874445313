import React from "react";
//styles
import "./../../styles/testimonials.css";
// material ui
import { Box, Grid, Rating } from "@mui/material";
// images
import { TestimonialsImage } from "./../../assets/images";
// slider
import Carousel from "react-material-ui-carousel";
// svg
import { QuotationEndIcon, QuotationStartIcon } from "./../../assets/svg";
// animation
import { Fade } from "react-reveal";
// images
import {
  TestimonialsPorfile1,
  TestimonialsPorfile2,
  TestimonialsPorfile3,
  TestimonialsPorfile4,
} from "./../../assets/images";
const Testimonials = () => {
  const List = [
    {
      profile: TestimonialsPorfile1,
      description:
        "We hired GMITS last month for SEO Services. I was amazed to see one of my training courses showing on the first page of Google search in one week. They proved it that they are the best SEO Service providers in the UAE, and currently, they are revamping our website. I am thrilled with their honest approach to projects, transparency and of course, their price.",
      name: "Mr. Hussain",
      postition: "Owner of Civil Driving Institute",
    },
    {
      profile: TestimonialsPorfile2,
      description:
        "We needed to atomize and manage the day to day operations of our business activities. GMITS provided us with the best available and cost-effective cloud solution that helped our company in Sales, Accounting, HRMS and much more. One of the best ERP solutions with which we have worked.",
      name: "Mr. Muzaffar",
      postition: "CEO of wings.ae",
    },
    {
      profile: TestimonialsPorfile3,
      description:
        "ATMS hired GMITS for SEO service, and we were pleasantly surprised to have our company on the first page of Google within a matter of one week. The GMITS team is knowledgeable, responsive and committed to supporting our initiatives. We are delighted to have them as our partners.",
      name: "Dr. Hemant Kumar",
      postition: "Co-founder and Managing Director of ATMS",
    },
    {
      profile: TestimonialsPorfile4,
      description:
        "kausar was a great professional to work with. we worked together in a voters project in a very tight timetable. She is leading a very good team. Kausar is amazing at her job! she know what to do and how to do it. Her recommendations and adives helped me to deliver an attractive solution. Looking for more collaboration.",
      name: "Dr. Omar H. Salman",
      postition:
        "Business Development Manager | Associate Prof | PHD Computer Enginerring | Big Data Analytics",
    },
  ];
  return (
    <div className="testimonials-container">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          <Grid item xl={8} lg={10} md={10} sm={10} xs={12}>
            <Fade bottom delay={400}>
              <h1 className="testimonials-heading">TESTIMONIALS</h1>
              <h1 className="testimonials-heading-2">
                What Client Say <span>About Us</span>
              </h1>
            </Fade>
            <Grid container spacing={1}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Carousel
                  fullHeightHover={false}
                  autoPlay={true}
                  indicators={false}
                  swipe={true}
                  cycleNavigation={true}
                  animation="slide"
                  duration={2000}
                  navButtonsAlwaysInvisible={true}
                >
                  {List.map((v, i) => {
                    return (
                      <div className="testimonials-slider-container" key={i}>
                        <img
                          src={QuotationStartIcon}
                          alt="quotation"
                          className="testimonials-quotation"
                        />
                        <div className="testimonials-profile-main">
                          <img
                            src={v.profile}
                            alt="profile"
                            className="testimonials-profile"
                          />
                        </div>
                        <div className="testimonials-data-main">
                          <p className="testimonials-description">
                            {v.description}
                          </p>
                          <div>
                            <h1 className="testimonials-user-name">{v.name}</h1>
                            <p className="testimonials-postition">
                              {v.postition}
                            </p>
                            <Rating name="read-only" value={5} readOnly />
                            <div className="testimonials-quotation-main">
                              <img
                                src={QuotationEndIcon}
                                alt="quotation"
                                className="testimonials-quotation"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="testimonials-image-main">
                  <img
                    src={TestimonialsImage}
                    alt="Testimonials"
                    className="testimonials-image"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default Testimonials;
