import React, { useEffect } from "react";
// images
import { NotFoundImage } from "./../../assets/images";
// material ui
import { Box, Grid, Button } from "@mui/material";
// styles
import "./../../styles/not-found.css";
// router
import { useNavigate } from "react-router-dom";
const NotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <div className="not-found-container">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xl={1} lg={1} md={1} sm={12} xs={12}></Grid>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <h1 className="not-found-1-heading">We are sorry!</h1>
            <h3 className="not-found-1-sub-heading">
              The page you are looking for is not found.
            </h3>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <h1 className="not-found-error-code">404</h1>
            <h1 className="not-found-error-code-message">Not found</h1>
            <div className="not-found-button-main">
              <Button
                type="text"
                className="not-found-button"
                onClick={() => navigate("/")}
              >
                back to home page
              </Button>
            </div>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <div className="not-found-image-main">
              <img
                src={NotFoundImage}
                alt="page-not-found"
                className="not-found-image"
              />
            </div>
          </Grid>
          <Grid item xl={1} lg={1} md={1} sm={12} xs={12}></Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default NotFound;
