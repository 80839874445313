import React from "react";
// material ui
import { Box, Grid, Button } from "@mui/material";
// images
import {
  PortfolioImage1,
  PortfolioImage2,
  PortfolioImage3,
  PortfolioImage4,
  PortfolioImage5,
  PortfolioImage6,
  PortfolioImage7,
  PortfolioImage8,
  PortfolioImage9,
  PortfolioImage10,
} from "./../../assets/images";
// styles
import "./../../styles/portfolio.css";
// animation
import { Fade } from "react-reveal";
// slider
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const Portfolio = (props) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1800 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1800, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      removeArrowOnDeviceType: "tablet",
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const List = [
    {
      image: PortfolioImage1,
      title: "AWS LAW FIRM",
      subTitle: "Website Development",
      link: "",
    },
    {
      image: PortfolioImage2,
      title: "E-market",
      subTitle: "E-Commerce Website Development",
      link: "",
    },
    {
      image: PortfolioImage3,
      title: "Civil Academy",
      subTitle: "B2B/B2C Web & Mobile App Development",
      link: "",
    },
    {
      image: PortfolioImage4,
      title: "Beauty Birds",
      subTitle: "B2B/B2C Web & Mobile App Development",
      link: "https://beautybirds.net/",
    },
    {
      image: PortfolioImage5,
      title: "Futures Abroad",
      subTitle:
        "Website Revamping / Social Media Marketing / Lead Generation / SEO",
      link: "https://futuresabroad.com/",
    },
    {
      image: PortfolioImage6,
      title: "Dhanush International",
      subTitle: "Website Development",
      link: "http://dhanushintllc.com/",
    },
    {
      image: PortfolioImage7,
      title: "DMA Coaching",
      subTitle: "Website Development",
      link: "",
    },
    {
      image: PortfolioImage8,
      title: "Civil Academy",
      subTitle: "B2B/B2C Web & Mobile App Development",
      link: "https://civil.ae/",
    },
    {
      image: PortfolioImage9,
      title: "Luxury Car Rental Dubai",
      subTitle: "Social Media Marketing / SEO / Website Revamping",
      link: "https://www.rotanastar.ae/",
    },
    {
      image: PortfolioImage10,
      title: "Last Race Car",
      subTitle: "Social Media Marketing / GoogleAdwords / SEO",
      link: "",
    },
  ];
  return (
    <div className="portfolio-container">
      <Fade bottom delay={400}>
        <h1 className="portfolio-heading">OUR PORTFOLIO</h1>
        <h1 className="portfolio-sub-heading">
          Here is some of our <span>Recent Details</span>
        </h1>
      </Fade>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          <Grid item xl={8} lg={10} md={10} sm={10} xs={12}>
            <Carousel
              responsive={responsive}
              draggable={true}
              swipeable={true}
              removeArrowOnDeviceType="mobile"
              showDots={false}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              arrows={false}
              renderDotsOutside={false}
              autoPlay={props.deviceType !== "mobile" ? true : false}
              containerClass="carousel-container"
              deviceType={props.deviceType}
              dotListClass="custom-dot-list-style"
              className="_carousel"
            >
              {List.map((v, i) => {
                return (
                  <div className="portfolio-card" key={i}>
                    <img
                      src={v.image}
                      alt={v.title}
                      className="portfolio-card-image"
                    />
                    <p className="portfolio-card-title">{v.title}</p>
                    <p className="portfolio-card-description">{v.subTitle}</p>
                    {v.link !== "" && (
                      <Button
                        variant="text"
                        target="_blank"
                        href={v.link}
                        className="portfolio-card-view-button"
                      >
                        View project
                      </Button>
                    )}
                  </div>
                );
              })}
            </Carousel>
          </Grid>
          <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default Portfolio;
