import React from "react";
// styles
import "./../../styles/breadcrumbs.css";
// material ui
import { Button } from "@mui/material";
// icons
import { FaAngleRight } from "react-icons/fa";
// router
import { useNavigate } from "react-router-dom";
const Breadcrumbs = (props) => {
  const navigate = useNavigate();
console.log(props.image)
  return (
    <div className="breadcrumbs-container" style={{backgroundImage: `url(${props.image})`}}>
      <img src={props.image} className="breadcrumbs-image"/>
      <div className="breadcrumbs-data-main">
        <h1 className="breadcrumbs-heading">{props.heading}</h1>
        <div className="breadcrumbs-link-main">
          <Button
            type="text"
            disableRipple={true}
            className="breadcrumbs-home-button"
            onClick={() => navigate("/")}
          >
            Home
          </Button>
          <FaAngleRight className="breadcrumbs-right-icon" />
          {props.show && (
            <>
              <spna className="breadcrumbs-page">Services</spna>
              <FaAngleRight className="breadcrumbs-right-icon" />
            </>
          )}
          <spna className="breadcrumbs-page">{props.heading}</spna>
        </div>
      </div>
    </div>
  );
};
export default Breadcrumbs;
