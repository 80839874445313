import React, { useState } from "react";
// video player
import ReactPlayer from "react-player";
// material ui
import { Box, Grid } from "@mui/material";
// styles
import "./../../styles/testimonials-video.css";
// animation
import { Fade } from "react-reveal";
const TestimonialsVideo = () => {
  const [play, setPlay] = useState(false);
  return (
    <div className="testimonials-video-container">
      <Fade bottom delay={400}>
        <h1 className="testimonials-video-heading">
          CLIENT <span>TESTIMONIALS</span>
        </h1>
      </Fade>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          <Grid item xl={8} lg={10} md={10} sm={10} xs={12}>
          <Fade bottom delay={400}>
            <div className="testimonials-video">
              <ReactPlayer
                width="100%"
                height="100%"
                playing={play}
                pip
                controls="false"
                config={{ file: { forceHLS: true } }}
                url="https://youtu.be/wcxXvdOZ8X8"
              />
            </div>
            </Fade>
          </Grid>
          <Grid item xl={2} lg={10} md={1} sm={1} xs={12}></Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default TestimonialsVideo;
