import React from "react";
// styles
import "./../../styles/sub-footer.css";
// material ui
import { Button } from "@mui/material";
// router
import { useNavigate } from "react-router-dom";
const SubFooter = () => {
  const navigate = useNavigate();
  return (
    <div className="sub-footer-container">
      <p className="sub-footer-copyright">
        Copyright © 2022
        <Button
          type="text"
          disableRipple={true}
          className="sub-footer-button"
          onClick={() => navigate("/")}
        >
          GMITS TECH
        </Button>
        . All Rights Reserved.
      </p>
    </div>
  );
};
export default SubFooter;
