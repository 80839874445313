import React, { useEffect } from "react";
// layout
import Layout from "../../../layout";
// components
import {
  Breadcrumbs,
  StepCard,
  Tabs,
  GetInTouch,
  ContactUsSection,
} from "./../../../components";
// material ui
import { Box, Grid } from "@mui/material";
// images
import {
  MobileAppDevelopmentImage,
  MobileAppDevelopmentTabImage1,
  MobileAppDevelopmentTabImage2,
  MobileAppDevelopmentTabImage3,
  MobileAppDevelopmentHeader
} from "./../../../assets/images";
// animation
import { Fade } from "react-reveal";
// icons
import {
  AppleLogo,
  AndroidLogo,
  CrossPlatFormIcon,
  MobileAppDevelopmentWhiteIcon,
  MobileAppDevelopmentActiveIcon,
  HightlightIcon,
  HightlightWhiteIcon,
  NeedAppIcon,
  NeedAppWhiteIcon,
} from "./../../../assets/icons";
const MobileAppDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const CardList = [
    {
      icon: AndroidLogo,
      title: "Android Application Development",
      description:
        "We design and develop native android applications using Android Studio.",
    },
    {
      icon: AppleLogo,
      title: "iOS Application Development",
      description:
        "We design and develop native IOS applications using Xcode Studio.",
    },
    {
      icon: CrossPlatFormIcon,
      title: "Cross Platform App Development",
      description:
        "We design and develop cross platform applications using HTML5/CSS.",
    },
  ];
  const TabsList = [
    {
      icon: MobileAppDevelopmentActiveIcon,
      activeIcon: MobileAppDevelopmentWhiteIcon,
      title: "Mobile Apps",
      title2: "Steps",
      heading: "We Use Latest Tools & Technology",
      description:
        "In This Thriving And Ever-Changing World. Mobile Phones Are Not Only Used For Personal Communication, But It Has Become An Imperative Tool For Business Association And Connections Globally.Our Highly Skilled Team Of Developers Has Vast Experience In Using Latest Technologies To Custom Develop Mobile Apps On All Major Platforms Like Android, IPhone, Tablets And Other Smart Phone Applications.We Build Superior Quality And High-End User-Friendly Solutions And Merge With Back-End Integration By Implementing Top Of The Line Mechanism, Frameworks And Digital Acceptance.We Test The Functionalities And Make Sure The App Is Bug-Free Before We Launch The App",
      image: MobileAppDevelopmentTabImage1,
    },
    {
      icon: HightlightIcon,
      activeIcon: HightlightWhiteIcon,
      title: "HIGHLIGHTS OF",
      title2: "MOBILE APP",
      heading: "GMITS STRIVES TO OFFER THE BEST WORKING EXPERIENCE",
      description:
        "MAIN FEATURE OF MOBILE APP IS THAT IT  is user friendly and works seemlessly and fun to use.Mobile apps makes is easy for your customers to purchase your products or book your service at a click of a button.Current scenario is such that everyone wants the convenience to finish their task easily and mobile app helps us in achieving that goal, that is why mobile apps are very much used by every business who is striving for customer satisfaction and hence business growth",
      image: MobileAppDevelopmentTabImage2,
    },
    {
      icon: NeedAppIcon,
      activeIcon: NeedAppWhiteIcon,
      title: "WHY YOU NEED AN",
      title2: "MOBILE APP",
      heading: "HOW IT HELPS YOUR BUSINESS?",
      description:
        "Apps serve many Task and objectives: they provide general information about the product, prices, booking forms, search features, user accounts, messengers, news feeds, and much more. Build customer loyalty, In other words no matter what you are selling, You meet your customers’ needs and create an easy way to reach out to you. In addition having a messaging feature within your app can make a difference.Take your business to the next level and make life simpler for your customers by developing an App. Mobile App can contribute to brand awareness and facilitate quick checkout for a purchase, which ultimately leads to a sales increase. Apps can offer a more convenient way to browse and shop.",
      image: MobileAppDevelopmentTabImage3,
    },
  ];
  return (
    <Layout>
      <Breadcrumbs heading="Mobile App Development" show={true} image={MobileAppDevelopmentHeader}/>
      <div className="pages-section">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <div className="pages-section-data-main">
              <Fade bottom delay={400}>
                <h1 className="pages-section-heading">
                  MOBILE APP DEVELOPMENT COMPANY
                </h1>
              </Fade>
              <Fade bottom delay={800}>
                <p className="pages-section-description">
                  GMITS Tech is the best Mobile App development company in
                  dubai, UAE. WE Provide the Best And Affordable Android & IOS
                  Development in UAE, Mobile apps are preferred and recommended
                  by experts of the business world to contribute to brand
                  advertisement and awareness.
                  <br />
                  Mobile apps can play a vital role in reaching out to more and
                  more people. Therefore, be it android or apple store, we at
                  GMITS have experts in app development with reputable
                  experience in creating and managing mobile apps.
                </p>
              </Fade>
              </div>
            </Grid>
            <Grid item xl={4} lg={5} md={5} sm={5} xs={12}>
              <Fade bottom delay={1200}>
                <div className="pages-section-image-main">
                <img
                  src={MobileAppDevelopmentImage}
                  alt="Mobile App Development"
                  className="pages-section-image"
                />
                </div>
              </Fade>
            </Grid>
            <Grid item xl={2} lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Box>
      </div>
      <StepCard
        card={3}
        heading="HOW WE PROCESS THE MOBILE DEVELOPMENT"
        subHeading=""
        subHeading2=""
        subHeading3=""
        description="We Design Compatible, Highly Functional And Performance-Centric Mobile Apps For Startups And Enterprise Clients."
        CardList={CardList}
      />
      <Tabs
        heading="OUR "
        heading2="EXPERTISE"
        subHeading="Our highly experienced team of developers work on latest ongoing trends and technology with SEO perspective in mind to achieve the best outcome for your company."
        TabsList={TabsList}
      />
      <GetInTouch />
      <ContactUsSection />
    </Layout>
  );
};
export default MobileAppDevelopment;
